import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
export const OtherComponent = lazy(() => import("./OtherComponent"));
export const SecondComponent = lazy(() => import("./SecondComponent"));
const container = document.getElementById("root");

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render: Render an element to the root.
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />}>
          <Route
            path="expenses"
            element={
              <Suspense fallback={<div>loading</div>}>
                <OtherComponent />
              </Suspense>
            }
          />
          <Route
            path="invoices"
            element={
              <Suspense fallback={<div>loading</div>}>
                <SecondComponent />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
