import React, { lazy } from "react";
import { Link, Outlet } from "react-router-dom";
import "./App.css";
import logo from "./logo.svg";

export const OtherComponent = lazy(() => import("./OtherComponent"));
export const SecondComponent = lazy(() => import("./SecondComponent"));

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>This is the new title</p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React qweqwe
        </a>
        {/* <Suspense fallback={<div>loading</div>}>
          <OtherComponent />
        </Suspense> */}
        <Link to="invoices">Invoices</Link> |{" "}
        <Link to="expenses">Expenses</Link>
        <Outlet />
      </header>
    </div>
  );
}

export default App;
